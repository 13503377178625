<template>
  <div class="warp">
    <div class="header">
      <img src="../assets/header.png" alt="" />
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <img :src="item.book" alt="" class="book" @click="to(item.name)" />
        <div class="title">{{ item.name }}</div>
        <div class="">
          <img src="../assets/play.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {},
  // 定义属性
  data() {
    return {
      list: [
        { book: require("@/assets/保卫延安.jpg"), name: "保卫延安" },
        { book: require("@/assets/红岩.jpg"), name: "红岩" },
        { book: require("@/assets/黄河东流去.jpg"), name: "黄河东流去" },
        { book: require("@/assets/党旗下的誓言.png"), name: "党旗下的誓言" },
        { book: require("@/assets/大中国.png"), name: "大中国" },
        { book: require("@/assets/东方红.png"), name: "东方红" },
        { book: require("@/assets/歌唱祖国.png"), name: "歌唱祖国" },
        { book: require("@/assets/解放战争.jpg"), name: "解放战争" },
        {
          book: require("@/assets/守住底线--69封廉政家书.png"),
          name: "守住底线--69封廉政家书"
        },
        {
          book: require("@/assets/微党课--政治反腐.jpg"),
          name: "微党课--政治反腐"
        }
      ]
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    to(str) {
      this.$router.push({
        name: "play",
        query: {
          str: str
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header {
    width: 100vw;
    text-align: center;
    padding: 30px 0 0 0;

    img {
      width: 300px;
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
    height: 80vh;
    overflow-y: auto;

    .item {
      width: 375px;
      text-align: center;
      margin-bottom: 30px;

      .book {
        height: 200px;
      }

      div {
        font-size: 20px;
        margin-top: 20px;
        color: black;
        font-weight: bold;
        img {
          width: 40px;
        }
      }
    }
  }
}
</style>
